import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";
import { getInventory } from "../lib/api";

const Inventory = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [size, setSize] = useState(0);
  const [items, setItems] = useState([]);
  const [bagSlots, setBagSlots] = useState([]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }

    if (!user.activeCharacter) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  useEffect(() => {
    fetchInventory();
  }, []);

  async function fetchInventory() {
    let res = await getInventory();

    console.log("@ res", res.data);

    const { size, items, bagSlots } = res.data;

    setSize(size);
    setItems(items);
    setBagSlots(bagSlots);
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1 className="font-bold text-2xl">Inventory</h1>
        <p>Size: {size}</p>
      </div>

      {/* Bag Slots */}
      <div className="mt-2">
        <h2 className="font-bold text-xl">Bag Slots</h2>
        <div className="flex flex-row mt-2">
          {bagSlots.map((bagSlot) => {
            return (
              <div
                className="flex flex-col shadow-sm bg-white p-2 rounded mr-2"
                key={`bagslot-${bagSlot.ID}`}
              >
                <p>Bag Slot ID: {bagSlot.ID}</p>
                {!bagSlot?.Bag && <p>No bag equipped</p>}
              </div>
            );
          })}
        </div>
      </div>

      {/* Items */}
      <div className="mt-2">
        <h2 className="font-bold text-xl">
          Items ( {items?.length}/{size} )
        </h2>
        <div className="mt-2">
          {items.map((item) => {
            return (
              <div key={item?.ID} className="flex flex-row items-center">
                <img
                  width={50}
                  src={`https://greenrabbit.mypinata.cloud/ipfs/${item?.ItemTemplate?.Image}`}
                />
                <p className="ml-2">{item?.ItemTemplate?.Name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default observer(Inventory);
