import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";

const Bank = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }

    if (!user.activeCharacter) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1>Bank</h1>
      </div>

      <div></div>
    </div>
  );
};

export default observer(Bank);
