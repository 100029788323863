import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext } from "react";

class UIStore {
  iconsCollapsed = true;
  chatCollapsed = true;
  socialTabsCollapsed = true;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "UIStore",
      properties: ["iconsCollapsed", "chatCollapsed", "socialTabsCollapsed"],
      storage: window.localStorage,
    });
  }

  setCollapsed(key, val) {
    switch (key) {
      case "icons":
        this.iconsCollapsed = val;
        break;
      case "chat":
        this.chatCollapsed = val;
        break;
      case "socialTabs":
        this.socialTabsCollapsed = val;
        break;
    }
  }
}

const ui = new UIStore();

export const UIContext = createContext();

export default ui;
