import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";

import FacebookButton from "../components/FacebookButton";
import GoogleButton from "../components/GoogleButton";
import Button from "../components/Button";
import WaxButton from "../components/WaxButton";
import { signup } from "../lib/api";

const Signup = () => {
  return (
    <div className="flex w-full h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[url('/public/nanotopia-map.png')] bg-cover bg-center">
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full flex justify-center items-center backdrop-blur-sm"></div>

      <div className="flex flex-col items-center self-center px-10 py-10 drop-shadow backdrop-blur-sm bg-black/60 rounded-md">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          /> */}

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Create your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              console.log("@ validating", values);
              // if (!values.email) {
              //   errors.email = "Required";
              // } else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              // ) {
              //   errors.email = "Invalid email address";
              // }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              console.log("@ Got Submit", values);

              setSubmitting(true);

              try {
                let res = await signup(values);

                console.log("@ res", res);
              } catch (err) {
                console.log("@ err", err);
              }

              setSubmitting(false);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 pl-3 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Password
                    </label>
                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 pl-3 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Create Account
                  </Button>

                  <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-white"></div>
                    <span className="flex-shrink mx-4 text-white">
                      Or continue with
                    </span>
                    <div className="flex-grow border-t border-white"></div>
                  </div>

                  <FacebookButton />
                  <GoogleButton />

                  <WaxButton
                    onClick={() => {
                      // handleWaxLogin();
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <p className="mt-10 text-center text-sm text-white">
            Already have an account?{" "}
            <Link
              to={"/login"}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Login now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
