import { useNavigate } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { UserContext } from "../stores/userStore";
import { useContext, useEffect } from "react";

const Subscription = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }
  }, [user.isLoggedIn]);

  return (
    <div>
      <p>Subscription screen here</p>
    </div>
  );
};

export default observer(Subscription);
