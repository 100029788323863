import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";
import Button from "../components/Button";

import { NANOTOPIA_COLLECTION_NAME, VAULT_ACCOUNT } from "../lib/config";
import * as atomic from "../lib/atomic";

const CharacterImport = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [session, setSession] = useState(null);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }

    console.log("@ user id:", user.id);
  }, [user.isLoggedIn]);

  useEffect(() => {
    restoreSession();
  }, []);

  useEffect(() => {
    if (session) {
      getAssets();
    }
  }, [session]);

  async function restoreSession() {
    let sess = await user.sessionKit.restore();
    if (sess) {
      console.log("@ found session", sess);
      setSession(sess);
    }
  }

  async function logout() {
    await user.sessionKit.logout();
    setSession(null);
  }

  async function getAssets() {
    let assets = await atomic.getAssets({
      collectionName: NANOTOPIA_COLLECTION_NAME,
      schemaName: "character",
      owner: session?.actor.toString(),
    });

    console.log("@ character assets", assets);

    setAssets(assets);
  }

  async function sendAsset(asset) {
    try {
      let res = await session.transact(
        {
          action: {
            account: "atomicassets",
            name: "transfer",
            authorization: [session.permissionLevel],
            data: {
              from: session.actor,
              to: VAULT_ACCOUNT,
              asset_ids: [asset?.asset_id],
              memo: `character|${user.id}`,
            },
          },
        },
        {
          broadcst: true,
        }
      );

      setAssets(
        assets.filter((a) => Number(a?.asset_id) !== Number(asset?.asset_id))
      );
    } catch (err) {
      console.log("@ err", err);

      // TODO: Handler error
    }
  }

  function getImage(asset) {
    let hash = asset?.data?.img ?? "";
    return `https://greenrabbit.mypinata.cloud/ipfs/${hash}`;
  }

  return (
    <div>
      <div className="flex flex-col">
        <h1 className="mb-2 text-3xl font-bold dark:text-white leading-none tracking-tight text-gray-900 ">
          Import Character
        </h1>
      </div>

      {!session && (
        <div className="rounded bg-white p-2 shadow-md">
          <p>
            Greetings traveller, please identify yourself to the Waygate warden
          </p>
          <Button
            onClick={async () => {
              const response = await user.sessionKit.login();
              if (response?.session) {
                setSession(response.session);
              }
            }}
          >
            Login
          </Button>
        </div>
      )}

      {!!session && (
        <div>
          <div className="rounded bg-white p-2 shadow-md mt-2">
            <p>
              Hello there, We have identified you as{" "}
              <b>{session?.actor?.toString()}</b>, Please select the Character/s
              below you wish to bring through the Waygate and into Nanotopia
            </p>
            <Button
              onClick={() => {
                logout();
              }}
            >
              Logout
            </Button>
          </div>

          <div className="shadow-md grid grid-cols-4 gap-4 mt-2 bg-white p-2 rounded">
            <select
              id="collection"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={"greenrabbitx"}
            >
              <option selected>Choose a collection</option>
              <option value="greenrabbitx">Green Rabbit</option>
            </select>

            {/* TODO: Add schema */}

            {/* TODO: Add text search */}
          </div>

          {assets?.length === 0 && (
            <p>You don't have any characters to send through the waygate.</p>
          )}

          <div className="grid grid-cols-4 gap-4 mt-2">
            {assets.map((asset) => {
              return (
                <div
                  key={asset?.asset_id}
                  className="shadow-md rounded bg-white p-2"
                >
                  <img src={getImage(asset)} />
                  <p>
                    {asset?.name} | {asset?.data?.Username}
                  </p>

                  <Button
                    onClick={() => {
                      sendAsset(asset);
                    }}
                  >
                    Send
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(CharacterImport);
