export const IS_TESTNET = process.env.REACT_APP_IS_TESTNET;
export const GAME_API = process.env.REACT_APP_GAME_API;
export const WEBSOCKET_API = process.env.REACT_APP_WEBSOCKET_API;
export const CHAIN_API = process.env.REACT_APP_CHAIN_API;
export const ATOMIC_API = process.env.REACT_APP_ATOMIC_API;

export const GR_COLLECTION_NAME = process.env.REACT_APP_GR_COLLECTION_NAME;
export const NANOTOPIA_COLLECTION_NAME =
  process.env.REACT_APP_NANOTOPIA_COLLECTION_NAME;
export const VAULT_ACCOUNT = process.env.REACT_APP_VAULT_ACCOUNT;
export const ACCOUNTS_CONTRACT = process.env.REACT_APP_ACCOUNTS_CONTRACT;

let waygateAllowedCollections = [
  { collection: "greenrabbit", schemas: ["armor"] },
];
if (IS_TESTNET) {
  waygateAllowedCollections = [
    { collection: "greenrabbitx", schemas: ["armor"] },
  ];
}

export const WAYGATE_ALLOWED_COLLECTIONS = waygateAllowedCollections;
