import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext } from "react";

class CharacterCreateStore {
  firstLoadIn = true;

  slotId = null;
  username = "";

  gender = "";
  skinColor = "";
  hairColor = "";

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "CharacterCreateStore",
      properties: [
        "firstLoadIn",
        "slotId",
        "username",
        "gender",
        "skinColor",
        "hairColor",
      ],
      storage: window.localStorage,
    });
  }
  setFirstLoadIn(val) {
    this.firstLoadIn = val;
  }

  setSlotId(val) {
    this.slotId = val;
  }

  setUsername(val) {
    this.username = val;
  }

  setGender(val) {
    this.gender = val;
  }

  setSkinColor(val) {
    this.skinColor = val;
  }

  setHairColor(val) {
    this.hairColor = val;
  }

  get readyToCreate() {
    return (
      !!this.slotId &&
      !!this.username &&
      !!this.gender &&
      !!this.skinColor &&
      !!this.hairColor
    );
  }

  clear() {
    this.firstLoadIn = true;
    this.slotId = null;
    this.username = "";
    this.gender = "";
    this.skinColor = "";
    this.hairColor = "";
  }
}

const character = new CharacterCreateStore();

export const CharacterCreateContext = createContext();

export default character;
