import {
  Link,
  useLoaderData,
  useNavigate,
  useParams,
  useRouteError,
} from "react-router-dom";
import { verifyEmail } from "../lib/api";

import { observer } from "mobx-react-lite";
import { UserContext } from "../stores/userStore";
import { useContext, useEffect } from "react";

export async function loader({ params }) {
  const { token } = params;
  console.log("@ token from loader", token);

  //  Make request to backend to verify email
  let res = await verifyEmail({ token });

  return res;
}

const VerifyEmail = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const verifyResponse = useLoaderData();

  console.log("@ verifyResponse", verifyResponse);

  let { token } = useParams();

  console.log("VerifyEmail rendered");

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  return (
    <div>
      <p>Email Verification Successful! Welcome to Nanotopia.</p>
    </div>
  );
};

export const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);
  return <div>Email verification failed!</div>;
};

export default observer(VerifyEmail);
