import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { Field, Form, Formik } from "formik";
import { api, createCharacter } from "../lib/api";
import { observer } from "mobx-react-lite";

const ManageAssets = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [session, setSession] = useState();

  useEffect(() => {
    getSession();
  }, []);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }
  }, [user.isLoggedIn]);

  async function getSession() {
    const sess = await user.sessionKit.restore();
    console.log("@ sess", sess);
    setSession(sess);
  }

  async function handleLogin() {
    const response = await user.sessionKit.login();
    setSession(response.session);
  }

  async function handleLogout() {
    await user.sessionKit.logout();
    setSession(null);
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1>Manage Assets</h1>

        {/* <div>
          {!session && <Button onClick={handleLogin}>Login</Button>}
          {!!session && (
            <div className="flex flex-row items-center">
              <p className="mr-2">{session?.actor?.toString()}</p>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          )}
        </div> */}
      </div>

      <div className="pt-2">
        <div class="overflow-hidden rounded-xl border border-gray-100 bg-gray-50 p-1">
          <ul class="flex items-center gap-2 text-sm font-medium">
            <li class="flex-1">
              <a
                href="#"
                class="text-gra relative flex items-center justify-center gap-2 rounded-lg bg-white px-3 py-2 shadow hover:bg-white hover:text-gray-700"
              >
                Assets
              </a>
            </li>
            <li class="flex-1">
              <a
                href="#"
                class="flex items-center justify-center gap-2 rounded-lg px-3 py-2 text-gray-500 hover:bg-white hover:text-gray-700 hover:shadow"
              >
                Transfer In
              </a>
            </li>
            <li class="flex-1">
              <a
                href="#"
                class="flex items-center justify-center gap-2 rounded-lg px-3 py-2 text-gray-500 hover:bg-white hover:text-gray-700 hover:shadow"
              >
                Transfer History
                {/* <span class="rounded-full bg-gray-100 px-2 py-0.5 text-xs font-semibold text-gray-500">
                  {" "}
                  8{" "}
                </span> */}
              </a>
            </li>
            {/*
            <li class="flex-1">
              <a
                href="#"
                class="flex items-center justify-center gap-2 rounded-lg px-3 py-2 text-gray-500 hover:bg-white hover:text-gray-700 hover:shadow"
              >
                Applications
              </a>
            </li>
            <li class="flex-1">
              <a
                href="#"
                class="flex items-center justify-center gap-2 rounded-lg px-3 py-2 text-gray-500 hover:bg-white hover:text-gray-700 hover:shadow"
              >
                API
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default observer(ManageAssets);
