import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";

import "leaflet/dist/leaflet.css";

import {
  MapContainer,
  TileLayer,
  ImageOverlay,
  useMap,
  Marker,
  Popup,
  CircleMarker,
  Tooltip,
  ZoomControl,
  Circle,
} from "react-leaflet";
import { CRS } from "leaflet";
import Button from "../components/Button";

const CurrentLocation = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          // setPosition(marker.getLatLng());
          console.log(marker.getLatLng());
        }
      },
    }),
    []
  );

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }

    if (!user.activeCharacter) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  const bounds = [
    [0, 0],
    [2080, 2920],
  ];

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col h-full w-full">
        <MapContainer
          minZoom={-1}
          maxZoom={0}
          bounds={bounds}
          maxBounds={[
            [0, 0],
            [2240, 2920],
          ]}
          maxBoundsViscosity={1.0}
          style={{ height: "100%", backgroundColor: "black" }}
          scrollWheelZoom={true}
          zoomControl={false}
          crs={CRS.Simple}
        >
          <ImageOverlay url="/maps/paragon-city.png" bounds={bounds} />

          {/* <Marker position={[600, 1151.5]}>
            <Popup>
              <h1 className=" text-gray-950 font-semibold text-xl">Waygate</h1>
              <p>
                A mysterious waygate that few dare to enter, and those that do
                never return. Legends tell of lucky nanotopians who have been on
                the receiving end of mysterious items that have come through the
                waygate from far away realms.
              </p>
              <br />
              <Button
                onClick={() => {
                  navigate("/waygate");
                }}
              >
                Use Waygate
              </Button>
            </Popup>
          </Marker> */}

          <Circle
            center={[750, 1151.5]}
            eventHandlers={{
              click: () => {
                navigate("/waygate");
              },
            }}
            pathOptions={{ color: "transparent" }}
            radius={150}
          >
            <Tooltip>Paragon City Waygate</Tooltip>
          </Circle>

          {/* <Marker position={[248, 384]}>
            <Popup>
              <h1 className=" text-gray-950 font-semibold text-xl">
                Paragon Bank
              </h1>
              <p>A secure place to store your items after a long journey</p>
              <br />
              <Button
                onClick={() => {
                  navigate("/bank");
                }}
              >
                Use Bank
              </Button>
            </Popup>
          </Marker> */}

          {/* 
          <Marker position={[1765, 395]}>
            <Popup keepInView={true}>
              <h1 className=" text-gray-950 font-semibold text-xl">
                Paragon Depths Dungeon
              </h1>

              <br />
              <Button
                onClick={() => {
                  navigate("/dungeon/paragon-city");
                }}
              >
                Go To Dungeon
              </Button>
            </Popup>
          </Marker> */}

          <Circle
            center={[1800, 395]}
            eventHandlers={{
              click: () => {
                console.log("@ got click");
                navigate("/dungeon/paragon-depths");
              },
            }}
            pathOptions={{ color: "transparent" }}
            radius={100}
          >
            <Tooltip>Paragon Depths Dungeon</Tooltip>
          </Circle>

          <ZoomControl position="bottomright" />
        </MapContainer>
      </div>
    </div>
  );
};

export default observer(CurrentLocation);
