import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import Button from "../components/Button";
import Modal from "../components/Modal";

import {
  api,
  createCharacter,
  enterWorld,
  getCharacterSlots,
  withdrawCharacter,
} from "../lib/api";
import { observer } from "mobx-react-lite";

const CharacterSelect = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [characterSlots, setCharacterSlots] = useState([]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
      return;
    }

    loadCharacterSlots();
  }, [user.isLoggedIn]);

  async function loadCharacterSlots() {
    let res = await getCharacterSlots();

    console.log("@ getCharacterSlots", res.data.CharacterSlots);
    setCharacterSlots(res?.data?.CharacterSlots ?? []);
  }

  async function handleEnterWorld(character) {
    try {
      let res = await enterWorld({
        CharacterID: character?.ID,
      });

      console.log("@ res", res);

      if (res?.data?.Token) {
        // update token to use token with character selected
        user.setToken(res?.data?.Token);

        user.setActiveCharacter(character);
        navigate("/");
      }
    } catch (err) {
      console.log("@ enter world err", err);
    }
  }

  async function handleWithdraw(character) {
    console.log("@ withdrawing character", character);

    try {
      let res = await withdrawCharacter(character.ID);

      console.log("@ withdraw res", res);

      loadCharacterSlots();
    } catch (err) {
      console.log("@ err", err);
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1>Select Character</h1>
      </div>

      <div className="flex flex-row mt-5">
        {characterSlots?.map((characterSlot) => {
          return (
            <div
              key={characterSlot?.ID}
              className="flex flex-col items-center border-dashed border-2 rounded-md p-10 border-gray-300"
            >
              {!!characterSlot?.Character && (
                <>
                  <p>{characterSlot?.Character?.Username}</p>
                  <Button
                    onClick={() => {
                      handleEnterWorld(characterSlot?.character);
                    }}
                  >
                    Enter World
                  </Button>
                  <div className="h-2" />
                  <Button
                    onClick={() => {
                      handleWithdraw(characterSlot?.Character);
                    }}
                  >
                    Withdraw
                  </Button>
                </>
              )}

              {!characterSlot?.Character && (
                <>
                  <Button
                    onClick={() => {
                      navigate(
                        `/character-select/create?slotId=${characterSlot.ID}`
                      );
                    }}
                  >
                    Create Character
                  </Button>
                  <div className="h-2" />
                  <Button
                    onClick={() => {
                      navigate(
                        `/character-select/add?slotId=${characterSlot.ID}`
                      );
                    }}
                  >
                    Add Character
                  </Button>
                </>
              )}
            </div>
          );
        })}

        {/* <div className="flex flex-col border-dashed border-2 rounded-md p-10 border-gray-300 ml-5">
          <p>Character 2</p>
          <Button>Play</Button>
        </div> */}
      </div>

      <div className="flex flex-row mt-5">
        <Button
          onClick={() => {
            navigate("/character/select/import");
          }}
        >
          Import Character
        </Button>
      </div>
    </div>
  );
};

export default observer(CharacterSelect);
