import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";
import Button from "../components/Button";
import { getInventory, withdrawItems } from "../lib/api";

const WaygateSend = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [session, setSession] = useState(null);

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }

    if (!user.activeCharacter) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  useEffect(() => {
    fetchInventory();
  }, []);

  async function fetchInventory() {
    try {
      let res = await getInventory();

      const { size, items, bagSlots } = res.data;

      // setSize(size);
      setItems(items);
      // setBagSlots(bagSlots);
    } catch (err) {
      console.log("@ get inv err", err);
    }
  }

  async function handleWithdrawItems() {
    console.log("@ selected", selected);
    // return;

    // let itemsToWithdraw = items.filter((item) => selected.includes(item.ID));

    // console.log("@ itemsToWithdraw", itemsToWithdraw);

    try {
      let res = await withdrawItems(selected);

      console.log("@ withdraw items res", res);

      fetchInventory();
    } catch (err) {
      console.log("@ withdraw items err", err);
    }
  }

  return (
    <div>
      <div className="flex flex-col">
        <h1 className="mb-2 text-3xl font-bold dark:text-white leading-none tracking-tight text-gray-900 ">
          Waygate
        </h1>
      </div>

      <div>
        <h2>Items</h2>

        {selected?.length > 0 && (
          <Button onClick={handleWithdrawItems.bind(null, this)}>
            Withdraw Selected Items
          </Button>
        )}

        <div className="mt-5">
          {items.map((item) => {
            return (
              <div key={item?.ID} className="flex flex-row items-center mb-2">
                <input
                  type="checkbox"
                  className="mr-5"
                  checked={selected.includes(item?.ID)}
                  onChange={() => {
                    console.log("@ item", item);
                    if (selected.includes(item?.ID)) {
                      setSelected(selected.filter((e) => e !== item.ID));
                    } else {
                      setSelected(selected.concat([item.ID]));
                    }
                  }}
                />
                <img
                  width={50}
                  src={`https://greenrabbit.mypinata.cloud/ipfs/${item?.ItemTemplate?.Image}`}
                />
                <p>{item?.ItemTemplate?.Name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default observer(WaygateSend);
