import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext } from "react";
import { jwtDecode } from "jwt-decode";

class UserStore {
  id = null;
  token = null;
  activeCharacter = null;

  characters = [];
  linkedWallets = [];

  sessionKit = null;
  contractKit = null;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "UserStore",
      properties: ["id", "token", "activeCharacter"],
      storage: window.localStorage,
    });
  }

  setCharacters(val) {
    this.characters = val;
  }

  setLinkedWallets(val) {
    this.linkedWallets = val;
  }

  setToken(token) {
    this.token = token;
    const decoded = jwtDecode(token);
    this.id = decoded.userId;
  }

  setSessionKit(val) {
    this.sessionKit = val;
  }

  setContractKit(val) {
    this.contractKit = val;
  }

  setActiveCharacter(val) {
    this.activeCharacter = val;
  }

  logout() {
    this.id = null;
    this.token = null;
    this.activeCharacter = null;
  }

  get isLoggedIn() {
    return !!this.token;
  }
}

const user = new UserStore();

export const UserContext = createContext();

export default user;
