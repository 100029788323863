import axios from "axios";
import { ATOMIC_API } from "./config";

export const api = axios.create({
  baseURL: ATOMIC_API,
  timeout: 10000,
  headers: {},
});

export async function getAssets({ collectionName, schemaName, owner }) {
  let res = await api.get("/atomicassets/v1/assets", {
    params: {
      collection_name: collectionName,
      schema_name: schemaName,
      owner,
    },
  });

  const { data } = res;

  console.log("data", data);

  let assets = [];

  if (data.success) {
    assets = data.data;
  }

  return assets;
}
