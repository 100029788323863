import { useContext, useEffect, useRef } from "react";
import { UserContext } from "../stores/userStore";
import { Centrifuge } from "centrifuge";
import { WEBSOCKET_API } from "../lib/config";

export const useCentrifuge = ({
  onConnecting = (ctx) => {},
  onConnected = (ctx) => {},
  onDisconnected = (ctx) => {},
  onPublication = (ctx) => {},
  onError = (ctx) => {},
  onSubscribed = (ctx) => {},
}) => {
  const cf = useRef(null);
  const userStore = useContext(UserContext);

  useEffect(() => {
    const centrifuge = new Centrifuge(
      `${WEBSOCKET_API}/chat/connection/websocket`, // TODO: Maybe make this path configurable
      {
        token: userStore.token,
      }
    );

    centrifuge.on("connecting", onConnecting);
    centrifuge.on("disconnected", onDisconnected);
    centrifuge.on("connected", onConnected);
    centrifuge.on("publication", onPublication);
    centrifuge.on("error", onError);
    centrifuge.on("subscribed", onSubscribed);

    // Trigger actual connection establishement.
    centrifuge.connect();

    cf.current = centrifuge;

    return () => {
      centrifuge.disconnect();
    };
  }, []);

  // bind is needed to make sure `send` references correct `this`
  return [cf.current];
};
