import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";

import { observer } from "mobx-react-lite";
import Grid from "../components/Grid";
import Button from "../components/Button";
import { AnimatePresence, motion } from "framer-motion";
import { CharacterDetails } from "./CharacterSelection";
import { CharacterCreateContext } from "../stores/characterCreate";
import { createCharacter } from "../lib/api";
import toast from "react-hot-toast";

const CharacterCreation = () => {
  const navigate = useNavigate();

  const character = useContext(CharacterCreateContext);

  async function handleGoBack() {
    navigate("/character/name");
  }

  async function handleCreateCharacter() {
    console.log("@ creating character", character);

    try {
      let res = await createCharacter({
        CharacterSlotID: character.slotId,
        Username: character.username,
        Gender: character.gender,
        SkinColor: character.skinColor,
        HairColor: character.hairColor,
      });

      console.log("@ createCharacter res", res);

      character.clear();

      // Show some kind of success popup
      toast.success("Character created successfully!");

      // navigate back to /character/select ???
      navigate("/character/select");
    } catch (err) {
      console.log("@ createCharacter err", err);
    }
  }

  return (
    <div className="">
      <div
        className="
        relative
        overflow-hidden
        block
        z-10
        w-screen 
        h-[calc(100vh)]       
      "
        style={{
          background:
            "radial-gradient(51.35% 56.11% at 50% 43.89%, rgba(11, 24, 29, 0.04) 0%, rgba(11, 24, 29, 0.18) 70.73%), url('/character-selection/nanotopia-bg-map.png') lightgray 50% / cover no-repeat",
        }}
      ></div>

      <AnimatePresence mode="wait">
        <motion.div
          key="bg-panel"
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          exit={{ y: -1000 }}
          transition={{
            ease: "linear",
          }}
          style={{
            fill: "rgba(46, 56, 74, 0.57)",
            boxShadow:
              "83.583px -83.583px 83.583px 0px rgba(165, 165, 165, 0.01) inset, -83.583px 83.583px 83.583px 0px rgba(255, 255, 255, 0.01) inset",
            backdropFilter: "blur(11px)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "calc(100vh - 200px)",
            zIndex: 21,
            clipPath:
              "polygon(0 0, 100% 0, 100% 100%, 85% 93%, 9% 95%, 0 100%)",
            overflow: "hidden",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="238"
            height="auto"
            viewBox="0 0 238 775"
            fill="none"
            className="absolute left-0 right-0 bottom-0 top-0 z-[16]"
          >
            <g filter="url(#filter0_d_542_1804)">
              <path
                d="M-119 -107L229 -74.5L104 735L-92 769.5L-246 703L-119 -107Z"
                fill="url(#paint0_linear_542_1804)"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_542_1804"
                x="-246"
                y="-110"
                width="484"
                height="884.5"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="5" dy="1" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_542_1804"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_542_1804"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_542_1804"
                x1="-8.15727"
                y1="-95.5832"
                x2="-8.15727"
                y2="690.101"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.21" stopColor="#24374C" />
                <stop offset="0.98" stopColor="#0D1C27" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="178"
            height="785"
            viewBox="0 0 178 785"
            fill="none"
            className="absolute right-0 bottom-0 top-0 z-[16]"
          >
            <g filter="url(#filter0_d_542_1805)">
              <path
                d="M346.5 -48L135.5 -65L0 736L179 779.5L346.5 703V-48Z"
                fill="url(#paint0_linear_542_1805)"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_542_1805"
                x="0"
                y="-68"
                width="355.5"
                height="852.5"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="5" dy="1" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_542_1805"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_542_1805"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_542_1805"
                x1="173"
                y1="-54"
                x2="173"
                y2="703"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.21" stopColor="#24374C" />
                <stop offset="0.98" stopColor="#0D1C27" />
              </linearGradient>
            </defs>
          </svg>
        </motion.div>
      </AnimatePresence>

      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(22, 37, 47, 0) 61.48%, #111E27 67.66%)",
        }}
        className="   
        z-20
        top-0
        bottom-0
        right-0
        left-0
        absolute
        overflow-hidden
      "
      >
        <Grid />

        {/* Blue Grid Overlay */}
        {/* <div className="absolute bottom-0 left-0 right-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="auto"
            height="332"
            viewBox="0 0 1189 332"
            fill="none"
          >
            <path
              d="M821.5 0.5H375L0.5 396.5L1188.5 371.5L821.5 0.5Z"
              fill="url(#paint0_linear_538_1570)"
              style={{ mixBlendMode: "color-dodge" }}
            />
            <defs>
              <linearGradient
                id="paint0_linear_538_1570"
                x1="495.281"
                y1="28.5619"
                x2="497.807"
                y2="220.163"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#0085FF" />
                <stop offset="0.79" stop-color="#5C5C5C" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div> */}
      </div>

      <div
        className="   
        z-30
        top-0
        bottom-0
        right-0
        left-0
        absolute
        overflow-hidden
      "
      >
        <div className="flex flex-col absolute bottom-[100px] right-0 left-0 top-0 items-center justify-center pl-10 pr-10">
          <div className="flex flex-row justify-center">
            {/* Left Options */}
            <AnimatePresence mode="wait">
              <motion.div
                key="left-opts"
                initial={{ y: -1000 }}
                animate={{ y: 0 }}
                exit={{ y: -1000 }}
                transition={{
                  ease: "linear",
                }}
              >
                {/* Gender Options */}
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="316"
                    height="202"
                    viewBox="0 0 316 202"
                    fill="none"
                  >
                    <g filter="url(#filter0_bd_538_1596)">
                      <path
                        d="M311.45 193.05H3.99994V32.5L311.45 0V193.05Z"
                        fill="black"
                        fillOpacity="0.3"
                        shapeRendering="crispEdges"
                      />
                      <path
                        d="M4.49994 32.9499V192.55H310.95V0.55564L4.49994 32.9499Z"
                        stroke="url(#paint0_linear_538_1596)"
                        shapeRendering="crispEdges"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_bd_538_1596"
                        x="-11"
                        y="-15"
                        width="337.45"
                        height="223.05"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur
                          in="BackgroundImageFix"
                          stdDeviation="7.5"
                        />
                        <feComposite
                          in2="SourceAlpha"
                          operator="in"
                          result="effect1_backgroundBlur_538_1596"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="effect1_backgroundBlur_538_1596"
                          result="effect2_dropShadow_538_1596"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect2_dropShadow_538_1596"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_538_1596"
                        x1="-19"
                        y1="130.5"
                        x2="338.5"
                        y2="86.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop
                          offset="0.355"
                          stopColor="white"
                          stop-opacity="0"
                        />
                        <stop
                          offset="0.65"
                          stopColor="white"
                          stop-opacity="0"
                        />
                        <stop offset="1" stopColor="white" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div className="absolute top-0 bottom-0 right-0 left-0 flex flex-col justify-start items-center py-10">
                    <p className="text-white font-bold text-3xl font-['Iceland'] -rotate-[5.08deg]">
                      Gender
                    </p>

                    <div className="flex flex-row self-stretch items-center flex-1 justify-evenly -rotate-[3.81deg]">
                      {["Male", "Female", "Enby"].map((gender) => {
                        const selected = character?.gender === gender;
                        return (
                          <div className="flex flex-col items-center">
                            <p className="text-white text-2xl font-['Iceland']">
                              {gender}
                            </p>

                            <svg
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill={selected ? "rgba(0, 239, 169, 1)" : "none"}
                              xmlns="http://www.w3.org/2000/svg"
                              className="mt-2 cursor-pointer"
                              onClick={() => {
                                if (selected) {
                                  character.setGender("");
                                } else {
                                  character.setGender(gender);
                                }
                              }}
                            >
                              <g id="Checkbox">
                                <path
                                  id="Rectangle 57"
                                  d="M0.816031 19.419V6.15316L9.05185 1.6299L17.06 1.09603V14.6711L8.83213 18.8846L0.816031 19.419Z"
                                  stroke="white"
                                />
                              </g>
                            </svg>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* Skin Color Options */}
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="316"
                    height="202"
                    viewBox="0 0 316 202"
                    fill="none"
                  >
                    <g filter="url(#filter0_bd_538_1597)">
                      <path
                        d="M311.45 4.57764e-05H3.99994V160.55L311.45 193.05V4.57764e-05Z"
                        fill="black"
                        fillOpacity="0.3"
                        shapeRendering="crispEdges"
                      />
                      <path
                        d="M4.49994 160.1V0.500046H310.95V192.494L4.49994 160.1Z"
                        stroke="url(#paint0_linear_538_1597)"
                        shapeRendering="crispEdges"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_bd_538_1597"
                        x="-11"
                        y="-14.9999"
                        width="337.45"
                        height="223.05"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur
                          in="BackgroundImageFix"
                          stdDeviation="7.5"
                        />
                        <feComposite
                          in2="SourceAlpha"
                          operator="in"
                          result="effect1_backgroundBlur_538_1597"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="effect1_backgroundBlur_538_1597"
                          result="effect2_dropShadow_538_1597"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect2_dropShadow_538_1597"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_538_1597"
                        x1="-19"
                        y1="62.55"
                        x2="338.5"
                        y2="106.55"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop
                          offset="0.355"
                          stopColor="white"
                          stop-opacity="0"
                        />
                        <stop
                          offset="0.65"
                          stopColor="white"
                          stop-opacity="0"
                        />
                        <stop offset="1" stopColor="white" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div className="absolute top-0 bottom-0 right-0 left-0 flex flex-col justify-start items-center py-10">
                    <p className="text-white font-bold text-3xl font-['Iceland'] rotate-[2.54deg]">
                      Skin Color
                    </p>

                    <div className="flex flex-row self-stretch items-center flex-1 justify-evenly rotate-[6.34deg] px-5">
                      {["green", "olive", "brown", "black", "white"].map(
                        (color) => {
                          const selected = character?.skinColor === color;

                          const colorToHex = {
                            green: "rgba(14, 130, 74, 1)",
                            olive: "rgba(78, 137, 21, 1)",
                            brown: "rgba(136, 57, 12, 1)",
                            black: "rgba(0, 0, 0, 1)",
                            white: "rgba(255, 255, 255, 1)",
                          };

                          return (
                            <div className="flex flex-col items-center">
                              <ColorOption
                                color={colorToHex[color]}
                                selected={selected}
                                onClick={() => {
                                  if (selected) {
                                    character.setSkinColor("");
                                  } else {
                                    character.setSkinColor(color);
                                  }
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>

            {/* Middle Character */}

            <div className="w-[350px] flex flex-col items-center">
              <motion.p
                layout
                layoutId="character-username"
                className="text-white text-4xl font-['Iceland']"
                style={{
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                {character.username}
              </motion.p>

              <AnimatePresence>
                <motion.img
                  key="character-preview"
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={false}
                  transition={{
                    ease: "linear",
                  }}
                  className="w-[400px] absolute bottom-0 left-0 right-0 m-auto"
                  src="/character-selection/example-character.png"
                />
              </AnimatePresence>
            </div>

            {/* Right Options */}
            <AnimatePresence mode="wait">
              <motion.div
                key="right-opts"
                initial={{ y: -1000 }}
                animate={{ y: 0 }}
                exit={{ y: -1000 }}
                transition={{
                  ease: "linear",
                }}
              >
                {/* Hair Color Options */}
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="316"
                    height="202"
                    viewBox="0 0 316 202"
                    fill="none"
                  >
                    <g filter="url(#filter0_bd_538_1598)">
                      <path
                        d="M4 193.05H311.45V32.5L4 0V193.05Z"
                        fill="black"
                        fillOpacity="0.3"
                        shapeRendering="crispEdges"
                      />
                      <path
                        d="M310.95 32.9499V192.55H4.5V0.55564L310.95 32.9499Z"
                        stroke="url(#paint0_linear_538_1598)"
                        shapeRendering="crispEdges"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_bd_538_1598"
                        x="-11"
                        y="-15"
                        width="337.45"
                        height="223.05"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur
                          in="BackgroundImageFix"
                          stdDeviation="7.5"
                        />
                        <feComposite
                          in2="SourceAlpha"
                          operator="in"
                          result="effect1_backgroundBlur_538_1598"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="effect1_backgroundBlur_538_1598"
                          result="effect2_dropShadow_538_1598"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect2_dropShadow_538_1598"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_538_1598"
                        x1="334.45"
                        y1="130.5"
                        x2="-23.05"
                        y2="86.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop
                          offset="0.355"
                          stopColor="white"
                          stop-opacity="0"
                        />
                        <stop
                          offset="0.65"
                          stopColor="white"
                          stop-opacity="0"
                        />
                        <stop offset="1" stopColor="white" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div className="absolute top-0 bottom-0 right-0 left-0 flex flex-col justify-start items-center py-10">
                    <p className="text-white font-bold text-3xl font-['Iceland'] rotate-[2.54deg]">
                      Hair Color
                    </p>

                    <div className="flex flex-row self-stretch items-center flex-1 justify-evenly rotate-[2.54deg] px-5">
                      {["transparent", "white", "blonde", "brown", "black"].map(
                        (color) => {
                          const selected = character?.hairColor === color;

                          const colorToHex = {
                            transparent: "rgba(0, 0, 0, 0)",
                            white: "rgba(255, 255, 255, 1)",
                            blonde: "rgba(231, 206, 71, 1)",
                            brown: "rgba(136, 57, 12, 1)",
                            black: "rgba(0, 0, 0, 1)",
                          };

                          return (
                            <div className="flex flex-col items-center">
                              <ColorOption
                                color={colorToHex[color]}
                                selected={selected}
                                onClick={() => {
                                  if (selected) {
                                    character.setHairColor("");
                                  } else {
                                    character.setHairColor(color);
                                  }
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="316"
                  height="202"
                  viewBox="0 0 316 202"
                  fill="none"
                >
                  <g filter="url(#filter0_bd_538_1599)">
                    <path
                      d="M4 -1.52588e-05H311.45V160.55L4 193.05V-1.52588e-05Z"
                      fill="black"
                      fillOpacity="0.3"
                      shapeRendering="crispEdges"
                    />
                    <path
                      d="M310.95 160.1V0.499985H4.5V192.494L310.95 160.1Z"
                      stroke="url(#paint0_linear_538_1599)"
                      shapeRendering="crispEdges"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_bd_538_1599"
                      x="-11"
                      y="-15"
                      width="337.45"
                      height="223.05"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="7.5"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_538_1599"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_538_1599"
                        result="effect2_dropShadow_538_1599"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_538_1599"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_538_1599"
                      x1="334.45"
                      y1="62.55"
                      x2="-23.05"
                      y2="106.55"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset="0.355" stopColor="white" stop-opacity="0" />
                      <stop offset="0.65" stopColor="white" stop-opacity="0" />
                      <stop offset="1" stopColor="white" />
                    </linearGradient>
                  </defs>
                </svg>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        <div className="flex flex-row absolute p-5 bottom-0 right-0 left-0 justify-between">
          {/* Left Stuff */}
          <div className="flex flex-1 flex-row justify-center"></div>

          {/* Middle */}
          <div className="flex flex-1 flex-col items-center">
            <motion.button
              onClick={handleCreateCharacter}
              disabled={!character.readyToCreate}
              layout
              layoutId="primary-button"
              whileHover={character.readyToCreate ? { scale: 1.05 } : {}}
              style={{
                border: "0.4px solid rgb(49, 249, 16, 0.5)",
                background: `rgba(11, 124, 97, ${
                  character.readyToCreate ? "1.0" : "0.30"
                })`,
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                display: "inline-flex",
                padding: "10px 80px",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
              }}
              className="
            text-white
              font-['Iceland']
              text-2xl
            "
            >
              Create Character
            </motion.button>
          </div>

          {/* Right Stuff */}
          <div className="flex flex-1 flex-row justify-center"></div>
        </div>
      </div>

      {/* Logo/Top Container */}
      <div
        style={{
          background: "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0))",
        }}
        className="flex flex-row justify-between absolute top-0 left-0 right-0 z-30"
      >
        <img
          src="/character-selection/nanotopia-logo.png"
          className="select-none"
        />

        <div className="flex flex-row pr-10 pt-10 items-start">
          <div className="flex flex-row items-center">
            <motion.div
              onClick={handleGoBack}
              whileHover={{ scale: 1.05 }}
              className="flex flex-row items-center cursor-pointer"
            >
              <p className="text-white text-2xl font-['Iceland']">Go Back</p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColorOption = ({ color, selected = false, onClick = () => {} }) => {
  return (
    <svg
      width="44"
      height="46"
      viewBox="0 0 44 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-2 cursor-pointer"
      onClick={onClick}
    >
      <g id="skinColor">
        {selected && (
          <>
            <rect
              x="0.5"
              y="1.1001"
              width="42.2"
              height="44.6"
              stroke="white"
            />
            <path
              id="Rectangle 57"
              d="M0.5 45.6999L0.5 11.3141L21.7141 1.09991L42.7 1.09991V36.275L21.4939 45.6999H0.5Z"
              fill={color}
              stroke="white"
            />
          </>
        )}

        {!selected && (
          <path
            id="Rectangle 57"
            d="M0 10.4L21.6 0H43.2V36L21.6 45.6H0V10.4Z"
            fill={color}
            stroke="white"
          />
        )}
      </g>
    </svg>
  );
};

export default observer(CharacterCreation);
