import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CharacterCreateContext } from "../stores/characterCreate";
import { UserContext } from "../stores/userStore";
import { checkUsername } from "../lib/api";

const CharacterName = () => {
  const navigate = useNavigate();

  const character = useContext(CharacterCreateContext);
  const user = useContext(UserContext);

  const [errors, setErrors] = useState([]);
  const [usernameExists, setUsernameExists] = useState("pending");

  useEffect(() => {
    if (character?.username) {
      debounceCheckUsername(character.username);
    }
  }, []);

  function handleGoBack() {
    if (user?.linkedWallets?.length === 0 && user?.characters?.length === 0) {
      navigate("/character/select");
    } else {
      navigate("/character/create-or-import");
    }
  }

  function handleNext() {
    if (character.username === "") {
      setErrors(["username must not be blank"]);
      return;
    }

    if (usernameExists === "pending") return;
    if (usernameExists === "exists") {
      setErrors(["this username is already in use"]);
      return;
    }

    navigate("/character/creation");
  }

  async function debounceCheckUsername(username) {
    // TODO: Debounce this
    // TODO: Check against api if username is in use
    try {
      setUsernameExists("pending");

      let res = await checkUsername(username);
      console.log("@ res", res?.data);

      if (res?.data?.Exists) {
        setUsernameExists("exists");
        setErrors(["this username is already in use"]);
      } else {
        setUsernameExists("available");
      }
    } catch (err) {
      console.log("@ err", err);
    }
  }

  return (
    <div
      className="
        relative
        overflow-hidden
        w-screen 
        h-screen
        flex flex-col items-center justify-center
        pt-2.5
        "
      style={{
        background:
          "radial-gradient(51.35% 56.11% at 50% 43.89%, rgba(11, 24, 29, 0.20) 0%, rgba(11, 24, 29, 0.90) 70.73%), url('/character-selection/nanotopia-bg-map.png') lightgray 50% / cover no-repeat",
      }}
    >
      <motion.div
        layout
        layoutId="create-character-create"
        className="relative"
      >
        <ModalSVG />

        <div className="absolute top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center px-20">
          <h1 className="font-bold text-5xl text-white font-['Iceland'] mb-14">
            Enter characters name
          </h1>

          <motion.input
            layout
            layoutId="character-username"
            className="w-full h-14 font-bold font-['Iceland'] text-4xl text-center"
            style={{
              background: "#FDFDFD",
              boxShadow: "2px 4px 4px 0px rgba(0, 0, 0, 0.40) inset",
            }}
            autoCorrect="false"
            autoComplete="false"
            type="text"
            placeholder="Enter your characters name..."
            value={character.username}
            maxLength={30}
            onChange={(e) => {
              setErrors([]);
              character.setUsername(e.target.value);
              debounceCheckUsername(e.target.value);
            }}
          />

          {errors.map((err) => {
            return (
              <p className="mt-5 text-red-600 text-2xl font-['Iceland']">
                {err}
              </p>
            );
          })}

          {usernameExists === "available" && (
            <p className="mt-5 text-green-600 text-2xl font-['Iceland']">
              Username available
            </p>
          )}
        </div>
      </motion.div>

      {/* Button Container */}
      <div className="flex flex-row self-stretch justify-evenly mt-10">
        <motion.button
          onClick={handleGoBack}
          whileHover={{ scale: 1.05 }}
          style={{
            border: "0.4px solid #31F910",
            background: `#0C191E`,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(2px)",
            padding: "10px 80px",
            gap: 10,
          }}
          className="
            text-white
              font-['Iceland']
              text-2xl
              inline-flex justify-center items-center
            "
        >
          Cancel
        </motion.button>

        <motion.button
          layout
          layoutId="primary-button"
          onClick={handleNext}
          whileHover={{ scale: 1.05 }}
          style={{
            border: "0.4px solid #31F910",
            background: `#0C191E`,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(2px)",
            padding: "10px 80px",
            gap: 10,
          }}
          className="
            text-white
              font-['Iceland']
              text-2xl inline-flex justify-center items-center
            "
        >
          Next
        </motion.button>
      </div>

      {/* Top Container */}
      <div className="flex flex-row justify-between absolute top-0 left-0 right-0 z-30">
        <img
          src="/character-selection/nanotopia-logo.png"
          className="select-none"
        />

        <div className="flex flex-row pr-10 pt-10 items-start">
          <div className="flex flex-row items-center">
            <motion.div
              onClick={handleGoBack}
              whileHover={{ scale: 1.05 }}
              className="flex flex-row items-center cursor-pointer"
            >
              <p className="text-white text-2xl font-['Iceland']">Go Back</p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="699"
      height="416"
      viewBox="0 0 699 416"
      fill="none"
    >
      <g filter="url(#filter0_bd_432_872)">
        <path
          d="M694.517 344.478C694.517 349.389 690.951 353.573 686.103 354.351L353.59 407.747C352.548 407.915 351.486 407.916 350.444 407.751L12.4391 354.334C7.57922 353.566 4.00012 349.376 4.00012 344.456V10C4.00012 4.47715 8.47729 0 14.0001 0H684.517C690.04 0 694.517 4.47715 694.517 10V344.478Z"
          fill="#0C191E"
        />
        <path
          d="M686.023 353.858C690.63 353.118 694.017 349.143 694.017 344.478V10C694.017 4.75329 689.764 0.5 684.517 0.5H14.0001C8.75342 0.5 4.50012 4.75329 4.50012 10V344.456C4.50012 349.13 7.90027 353.11 12.5172 353.84L350.522 407.257C351.512 407.414 352.521 407.413 353.511 407.254L686.023 353.858Z"
          stroke="url(#paint0_radial_432_872)"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_432_872"
          x="-11"
          y="-15"
          width="720.517"
          height="437.874"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_432_872"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_432_872"
            result="effect2_dropShadow_432_872"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_432_872"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_432_872"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(349.874 408) rotate(-180) scale(128.374 45.7946)"
        >
          <stop offset="0.548116" stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="white" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default observer(CharacterName);
