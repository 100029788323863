import { Formik, Form, Field, ErrorMessage } from "formik";

import FacebookButton from "../components/FacebookButton";
import GoogleButton from "../components/GoogleButton";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { login, waxLogin } from "../lib/api";

import { observer } from "mobx-react-lite";
import { UserContext } from "../stores/userStore";
import { useContext, useEffect } from "react";
import WaxButton from "../components/WaxButton";
import { ACCOUNTS_CONTRACT, GAME_API } from "../lib/config";
import Button from "../components/Button";

const arrayToHex = (data) => {
  let result = "";
  for (const x of data) {
    result += ("00" + x.toString(16)).slice(-2);
  }
  return result;
};

const Login = () => {
  const navigate = useNavigate();
  // const location = useLocation();

  const [searchParams] = useSearchParams();

  const user = useContext(UserContext);

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      // TODO: check we can decode token?

      // set in user as jwt
      user.setToken(searchParams.get("token"));
    }
  }, [searchParams]);

  async function handleWaxLogin() {
    let session;

    try {
      const response = await user.sessionKit.login();
      console.log("@ response", response);
      if (response?.session) {
        session = response.session;
      }
    } catch (err) {
      console.log("@ err", err);
      return;
    }

    const contract = await user.contractKit.load(ACCOUNTS_CONTRACT);

    const action = contract.action(
      "linkwallet",
      {
        link_code: 1234567,
      },
      {
        authorization: [session.permissionLevel],
      }
    );

    //  Trigger auth sign request
    const result = await session.transact(
      { action },
      {
        broadcast: false,
        expireSeconds: 120,
      }
    );

    console.log("@ transact result", result);

    const { signatures, resolved } = result;
    const { serializedTransaction } = resolved;

    const packedTrx = {
      signatures,
      compression: "none",
      packed_context_free_data: "",
      packed_trx: arrayToHex(serializedTransaction),
    };

    console.log("@ packedTrx", packedTrx);

    let loginRes = await waxLogin({
      waxAddress: session?.actor,
      transactionPayload: JSON.stringify(packedTrx),
    });

    console.log("@ loginRes", loginRes);

    const {
      data: { token },
    } = loginRes;

    user.setToken(token);
  }

  return (
    <div className="flex w-full h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[url('/public/nanotopia-map.png')] bg-cover bg-center">
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full flex justify-center items-center backdrop-blur-sm"></div>

      <div className="flex flex-col items-center self-center px-10 py-10 drop-shadow backdrop-blur-sm bg-black/60 rounded-md">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img
              className="mx-auto h-10 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            /> */}

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              console.log("@ validating", values);
              // if (!values.email) {
              //   errors.email = "Required";
              // } else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              // ) {
              //   errors.email = "Invalid email address";
              // }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              try {
                let res = await login(values);

                const {
                  data: { token },
                } = res;

                user.setToken(token);
              } catch (err) {
                console.log("@ err", err);
              }

              setSubmitting(false);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 pl-3 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Password
                    </label>
                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 pl-3 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  {/* <button
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button> */}

                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Sign in
                  </Button>

                  <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-white"></div>
                    <span className="flex-shrink mx-4 text-white">
                      Or continue with
                    </span>
                    <div className="flex-grow border-t border-white"></div>
                  </div>

                  <GoogleButton
                    onClick={() => {
                      window.location.href = `${GAME_API}/oauth/google`;
                    }}
                  />
                  <FacebookButton />
                  <WaxButton
                    onClick={() => {
                      handleWaxLogin();
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <p className="mt-10 text-center text-sm text-white">
            Don't have an account?{" "}
            <Link
              to={"/signup"}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign up now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(Login);
