import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { Field, Form, Formik } from "formik";
import {
  addCharacterToSlot,
  api,
  createCharacter,
  enterWorld,
  getCharacterSlots,
  getCharacters,
  withdrawCharacter,
} from "../lib/api";
import { observer } from "mobx-react-lite";

const CharacterSlotAdd = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [characters, setCharacters] = useState([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchCharacters();
  }, [user.isLoggedIn]);

  async function fetchCharacters() {
    try {
      let res = await getCharacters();

      console.log("@ get characters res", res);

      setCharacters(res?.data?.characters ?? []);
    } catch (err) {}
  }

  async function handleAddCharacter(character) {
    let characterSlotId = searchParams.get("slotId");

    try {
      console.log(
        "@ adding character",
        character,
        " to slot ",
        characterSlotId
      );

      let res = await addCharacterToSlot(character.ID, characterSlotId);

      console.log("@ character slot add res", res);

      navigate("/character/select");
    } catch (err) {
      console.log("@ add to slot err", err);
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1 className="font-bold text-2xl">Add Character to Slot</h1>
      </div>

      {/* Characters List */}
      <div>
        <h2 className="font-bold text-xl mt-5">Your Characters</h2>
        {characters.map((character) => {
          return (
            <div
              key={`character-${character?.ID}`}
              className="flex flex-row items-center mt-2"
            >
              <p className="mx-2">
                {character.Username} ( Lvl {character.Level} )
              </p>

              <Button onClick={handleAddCharacter.bind(null, character)}>
                Add Character
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(CharacterSlotAdd);
