import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";

import { observer } from "mobx-react-lite";
import Grid from "../components/Grid";

import { AnimatePresence, motion } from "framer-motion";
import { addCharacterToSlot, getCharacters } from "../lib/api";
import { CharacterCreateContext } from "../stores/characterCreate";
import toast from "react-hot-toast";

const CreateOrImport = () => {
  const navigate = useNavigate();

  const characterStore = useContext(CharacterCreateContext);
  const userStore = useContext(UserContext);

  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    fetchCharacters();
  }, []);

  async function fetchCharacters() {
    try {
      let res = await getCharacters();

      console.log("@ res", res);

      const { Characters } = res?.data;

      setCharacters(
        Characters?.filter((c) => {
          return !c.CharacterSlotID;
        })
      );
    } catch (err) {
      console.log("@ err getting characters", err);
    }
  }

  async function handleAddCharacter(character) {
    let characterSlotId = characterStore.slotId;

    try {
      console.log(
        "@ adding character",
        character,
        " to slot ",
        characterSlotId
      );

      let res = await addCharacterToSlot(character.ID, characterSlotId);

      console.log("@ character slot add res", res);

      toast.success("Character successfully added to slot!", {});

      navigate("/character/select");
    } catch (err) {
      console.log("@ add to slot err", err);
    }
  }

  async function handleGoBack() {
    navigate("/character/select");
  }

  return (
    <div className="">
      <div
        className="
        relative
        overflow-hidden
        block
        z-10   
        w-screen 
        h-screen      
      "
        style={{
          background:
            "radial-gradient(89.73% 98.06% at 61.59% -9.07%, rgba(11, 24, 29, 0.20) 0%, rgba(11, 24, 29, 0.95) 62.57%), url('/character-selection/nanotopia-bg-map.png') lightgray 50% / cover no-repeat",
        }}
      ></div>

      <div
        className="   
        z-30
        top-0
        bottom-0
        right-0
        left-0
        absolute
        overflow-hidden
      "
      >
        <div className="flex flex-row absolute right-0 left-0 top-0 bottom-0 items-center justify-start pl-10 pr-10 pt-32 overflow-y-scroll overflow-x-hidden">
          <motion.div
            layout
            layoutId="create-character-create"
            whileHover={{ scale: 1.05 }}
            className={`cursor-pointer relative flex flex-col justify-center items-center min-w-[345px]`}
            onClick={() => {
              navigate("/character/name");
            }}
          >
            <CreateCardSvg />

            <div className="flex flex-row justify-center items-center absolute top-0 bottom-0 right-0 left-0">
              <img
                src="/character-selection/square-plus.png"
                className="mr-[12px]"
              />
              <p className="text-white text-4xl font-['Iceland']">
                Create
                <br />
                Character
              </p>
            </div>
          </motion.div>

          <div className="flex flex-col flex-1">
            <div className="flex flex-row justify-between mb-5 ml-10">
              <p className="text-white text-3xl font-['Iceland']">
                Or Select Character to add to slot:
              </p>

              {userStore?.linkedWallets?.length > 0 && (
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="flex flex-row items-center"
                >
                  <img
                    className="w-5 mr-1"
                    src="/character-selection/square-plus.png"
                  />
                  <p
                    className="text-white text-xl font-['Iceland'] cursor-pointer"
                    onClick={() => {
                      navigate("/character/management");
                    }}
                  >
                    Import Character
                  </p>
                </motion.div>
              )}
            </div>

            <div
              style={{
                borderBottom: "1px solid #FFF",
                background: "rgba(255, 255, 255, 0.14)",
              }}
              className="w-full rounded-lg ml-5 px-10 py-10 grid gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 max-h-[50vh] overflow-y-scroll overflow-x-hidden"
            >
              {characters?.map((character) => {
                return (
                  <CharacterCard
                    character={character}
                    onClick={() => {
                      handleAddCharacter(character);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Logo/Top Container */}
      <div
        style={{
          background: "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0))",
        }}
        className="flex flex-row justify-between absolute top-0 left-0 right-0 z-30"
      >
        <img
          src="/character-selection/nanotopia-logo.png"
          className="select-none"
        />

        <div className="flex flex-row pr-10 pt-10 items-start">
          <div className="flex flex-row items-center">
            <motion.div
              onClick={handleGoBack}
              whileHover={{ scale: 1.05 }}
              className="flex flex-row items-center cursor-pointer"
            >
              <p className="text-white text-2xl font-['Iceland']">Go Back</p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CharacterCard = ({ character, onClick = () => {} }) => {
  return (
    <motion.div
      onClick={onClick}
      key={`character-${character?.ID}`}
      className="flex flex-row cursor-pointer"
      style={{
        borderRadius: 2,
        border: "1px solid rgba(255, 255, 255, 1.00)",
        background:
          "linear-gradient(180deg, rgba(51, 51, 51, 0.50) 0%, rgba(0, 0, 0, 0.50) 80%)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backdropFilter: "blur(10px)",
      }}
      whileHover={{ scale: 1.05 }}
    >
      {/* Character Details */}
      <div className="flex flex-col flex-1 pl-5 py-5">
        <p className="text-white text-2xl font-['Iceland'] mb-10">
          {character?.Username}
        </p>

        <p className="text-white text-xl font-['Iceland']">
          Level {character?.Level}
        </p>
        <p className="text-white text-xl font-['Iceland']">
          {character?.Location?.Name}
        </p>
      </div>

      {/* Character Image */}
      <div className="flex flex-row flex-1">
        <img
          className="object-cover object-right"
          src="/character-selection/anima-black-6.png"
        />
      </div>
    </motion.div>
  );
};

const CreateCardSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="308"
      height="194"
      viewBox="0 0 308 194"
      fill="none"
    >
      <g filter="url(#filter0_bd_1885_1452)">
        <path
          d="M307.45 193.05H-6.10352e-05V32.5L307.45 0V193.05Z"
          fill="black"
          fill-opacity="0.3"
          shape-rendering="crispEdges"
        />
        <path
          d="M0.749939 33.1749V192.3H306.7V0.83346L0.749939 33.1749Z"
          stroke="url(#paint0_linear_1885_1452)"
          stroke-width="1.5"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_1885_1452"
          x="-15"
          y="-15"
          width="337.45"
          height="223.05"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1885_1452"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_1885_1452"
            result="effect2_dropShadow_1885_1452"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1885_1452"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1885_1452"
          x1="-17"
          y1="255.5"
          x2="315.5"
          y2="-38.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.355" stop-color="white" stop-opacity="0" />
          <stop offset="0.65" stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default observer(CreateOrImport);
