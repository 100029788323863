import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";
import Button from "../components/Button";
import { getWithdrawals, getDeposits, claimDepositItems } from "../lib/api";

const Waygate = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [session, setSession] = useState(null);

  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);

  const [activeTab, setActiveTab] = useState("deposits");

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }

    if (!user.activeCharacter) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  useEffect(() => {
    restoreSession();
    fetchDeposits();
    fetchWithdrawals();
  }, []);

  async function fetchDeposits() {
    try {
      let res = await getDeposits();

      console.log("@ deposits res", res);

      const { deposits } = res?.data;

      console.log("@ deposits", deposits);

      setDeposits(deposits ?? []);
    } catch (err) {}
  }

  async function fetchWithdrawals() {
    try {
      let res = await getWithdrawals();

      console.log("@ withdrawals res", res);

      const { withdrawals } = res?.data;

      console.log("@ withdrawals", withdrawals);

      setWithdrawals(withdrawals ?? []);
    } catch (err) {}
  }

  async function restoreSession() {
    let sess = await user.sessionKit.restore();
    if (sess) {
      console.log("@ found session", sess);
      setSession(sess);
    }
  }

  async function handleClaimDeposit(deposit) {
    console.log("@ claiming deposit", deposit);

    try {
      let res = await claimDepositItems({
        Blockchain: "wax",
        DepositID: deposit.ID,
      });

      console.log("@ res", res);

      fetchDeposits();
    } catch (err) {
      console.log("@ claim err", err);
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1 className="font-bold text-2xl">Waygate</h1>
      </div>

      <hr />

      <div className="flex flex-row justify-around my-5">
        <div className="flex flex-col">
          <h2 className="mb-2">Bring Items into Nanotopia</h2>
          <Button
            onClick={() => {
              navigate("/waygate/receive");
            }}
          >
            Receive
          </Button>
        </div>

        <div className="flex flex-col">
          <h2 className="mb-2">Send Items back through Waygate</h2>
          <Button
            onClick={() => {
              navigate("/waygate/send");
            }}
          >
            Send
          </Button>
        </div>
      </div>

      <div>
        <div className="flex flex-row">
          <h2
            className={`${
              activeTab === "deposits" ? "underline" : ""
            } font-bold text-xl cursor-pointer shadow-md rounded-full my-2 py-2 px-5 bg-white`}
            onClick={() => {
              setActiveTab("deposits");
            }}
          >
            Deposits
          </h2>
          <h2
            className={`${
              activeTab === "withdrawals" ? "underline" : ""
            } font-bold text-xl ml-5 cursor-pointer shadow-md rounded-full my-2 py-2 px-5 bg-white`}
            onClick={() => {
              setActiveTab("withdrawals");
            }}
          >
            Withdrawals
          </h2>
        </div>

        {/* Deposits */}
        {activeTab === "deposits" && (
          <div>
            {deposits.map((deposit) => {
              let statusColor =
                deposit?.Status === "pending"
                  ? "text-yellow-500"
                  : "text-green-600";

              return (
                <div
                  key={deposit?.ID}
                  className="shadow-md rounded mb-2 bg-white p-2"
                >
                  <p>Created At: {deposit?.CreatedAt}</p>
                  <p>Deposit Type: {deposit?.DepositType}</p>
                  <p>
                    Status:{" "}
                    <span className={`${statusColor}`}>{deposit?.Status}</span>
                  </p>
                  <p>From: {deposit?.From}</p>
                  <p>Transfer ID: {deposit?.TransferID}</p>
                  <p>
                    Transaction:{" "}
                    <a
                      target="_blank"
                      href={`https://testnet.waxblock.io/transaction/${deposit?.TransactionID}`}
                    >
                      View
                    </a>
                  </p>

                  {!deposit?.Claimed && deposit?.Status === "confirmed" && (
                    <Button onClick={handleClaimDeposit.bind(null, deposit)}>
                      Move to Inventory
                    </Button>
                  )}
                  {deposit?.Claimed && deposit?.DepositType !== "character" && (
                    <p>Deposit Claimed</p>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {/* Withdrawals */}
        {activeTab === "withdrawals" && (
          <div>
            {withdrawals.map((withdrawal) => {
              let statusColor =
                withdrawal?.State === "processed"
                  ? "text-green-600"
                  : "text-yellow-500";

              return (
                <div
                  key={withdrawal?.ID}
                  className="shadow-md rounded mb-2 bg-white p-2"
                >
                  <p>Created At: {withdrawal?.CreatedAt}</p>
                  <p>Deposit Type: {withdrawal?.WithdrawalType}</p>
                  <p>
                    Status:{" "}
                    <span className={`${statusColor}`}>
                      {withdrawal?.State}
                    </span>
                  </p>
                  <p>Sent To: {withdrawal?.To}</p>

                  <p>
                    Transaction:{" "}
                    <a
                      target="_blank"
                      href={`https://testnet.waxblock.io/transaction/${withdrawal?.TransferTransactionID}`}
                    >
                      View
                    </a>
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Waygate);
