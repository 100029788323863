import { Link, Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { useContext } from "react";
import { observer } from "mobx-react-lite";
import Button from "../components/Button";

const AccountLayout = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  return (
    <div>
      {/* Header */}
      <div className="flex flex-row">
        {/* <div className="w-48 h-24 bg-gray-600">Logo</div> */}
      </div>

      {/* Sidebar */}
      <div className="flex min-h-screen flex-row bg-gray-100 text-gray-800">
        <aside className="flex sidebar w-48 -translate-x-full transform bg-white p-4 transition-transform duration-150 ease-in md:translate-x-0 md:shadow-md">
          {/* <div className="my-4 w-full border-b-4 border-indigo-100 text-center">
          <span className="font-mono text-xl font-bold tracking-widest">
          
          </span>
          </div>
        <div className="my-4"></div> */}
          <div className="flex flex-col justify-between">
            {/* Top */}
            <div className="flex flex-col">
              <Link className="mb-2" to={"/account"}>
                Account
              </Link>

              <Link className="mb-2" to={"/subscription"}>
                Subscription
              </Link>

              <Link className="mb-2" to={"/linked-wallets"}>
                Linked Wallets
              </Link>

              <Link className="mb-2" to={"/character/management"}>
                Character Management
              </Link>
            </div>

            {/* Bottom */}
            <div>
              {user.isLoggedIn && (
                <Button
                  onClick={() => {
                    // user.logout();
                    navigate("/character/select");
                  }}
                >
                  Character Selection
                </Button>
              )}
            </div>
          </div>
        </aside>
        <main className="main -ml-48 flex flex-grow flex-col p-4 transition-all duration-150 ease-in md:ml-0">
          {/* <div className="flex h-full items-center justify-center bg-white text-center text-5xl font-bold shadow-md">
          Content
        </div> */}
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default observer(AccountLayout);
