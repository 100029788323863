const Grid = ({}) => {
  return (
    <svg
      width="auto"
      height="345"
      viewBox="0 0 1920 345"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        bottom: 0,
        // backgroundColor: "rgba(17,30,39,1.00)",
      }}
    >
      <path
        d="M857.403 1.64117L445.772 843.473"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M538.168 1.64117L-71.998 314.012"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M431.754 1.64117L-71.998 207.976"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M325.35 1.64117L-71.9999 137.274"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M644.584 1.64117L-71.9998 490.704"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M218.935 1.64117L-71.9999 86.7654"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.10568 1.64117L-72 19.4183"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.52 1.64117L-72 48.8806"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1708.7 1.64117L2000 86.9197"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M963.818 1.64117L963.106 843.473"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1815.12 1.64117L2000 48.9922"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1602.29 1.64117L2000 137.481"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1495.87 1.64117L2000 208.261"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1283.05 1.64117L2000 491.518"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1389.47 1.64117L2000 314.471"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1070.23 1.64117L1480.45 843.473"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M750.989 1.64117L-71.5579 843.473"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1176.64 1.64117L1997.78 843.473"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 116.528H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 39.4842H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 55.1926H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 72.9586H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 12.9499H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 25.4926H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 143.628H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 93.2215H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 1.64117H-71.998"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 175.528H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 213.639H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 259.964H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2000 317.474H-71.9978"
        stroke="#B4B4B4"
        strokeOpacity="0.17"
        strokeWidth="0.802137"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Grid;
