import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { Field, Form, Formik } from "formik";
import {
  api,
  createCharacter,
  getLinkedWallets,
  linkWaxWallet,
} from "../lib/api";
import { observer } from "mobx-react-lite";
import { ACCOUNTS_CONTRACT } from "../lib/config";
import toast from "react-hot-toast";

const arrayToHex = (data) => {
  let result = "";
  for (const x of data) {
    result += ("00" + x.toString(16)).slice(-2);
  }
  return result;
};

const LinkWallet = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const wallets = [
    {
      key: "wax",
      displayName: "Wax",
      logo: "",
      linkFunc: waxLink,
    },
  ];

  const [linkedWallets, setLinkedWallets] = useState([]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchLinkedWallets();
  }, [user.isLoggedIn]);

  async function fetchLinkedWallets() {
    try {
      let res = await getLinkedWallets();
      setLinkedWallets(res?.data?.Wallets ?? []);
    } catch (err) {
      console.log("@ get linked wallets err", err);
    }
  }

  async function waxLink() {
    console.log("@ got wax link click");

    let session;

    try {
      const response = await user.sessionKit.login();
      if (response?.session) {
        session = response.session;
      }
    } catch (err) {
      console.log("@ err", err);
      return;
    }

    try {
      const contract = await user.contractKit.load(ACCOUNTS_CONTRACT);

      const action = contract.action(
        "linkwallet",
        {
          link_code: 1234567, // @@ TODO: Get this from somewhere!!!!
        },
        {
          authorization: [session.permissionLevel],
        }
      );

      //  Trigger auth sign request
      const result = await session.transact(
        { action },
        {
          broadcast: false,
          expireSeconds: 120,
        }
      );

      console.log("@ transact result", result);

      const { signatures, resolved } = result;
      const { serializedTransaction } = resolved;

      const packedTrx = {
        signatures,
        compression: "none",
        packed_context_free_data: "",
        packed_trx: arrayToHex(serializedTransaction),
      };

      console.log("@ packedTrx", packedTrx);

      let res = await linkWaxWallet({
        WaxAddress: session?.actor,
        TransactionPayload: JSON.stringify(packedTrx),
      });

      console.log("@ link wallet res", res);

      // Show success toast or something
      toast.success("Successfully linked wallet!");

      // Refetch linked wallets
      fetchLinkedWallets();
    } catch (err) {
      console.log("@ wax link error", err);
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1>Linked Wallets</h1>
      </div>

      <div className="pt-5">
        {wallets.map((wallet) => {
          const linkedWallet = linkedWallets.find(
            (w) => w.Blockchain === wallet.key
          );
          const isLinked = !!linkedWallet;

          return (
            <div
              key={`link-${wallet.key}-wallet`}
              className="flex flex-row justify-between items-center bg-white rounded-md px-5 py-3"
            >
              <p>{wallet.displayName} Blockchain</p>

              {isLinked && <p>{linkedWallet.Address}</p>}

              {!isLinked && (
                <Button onClick={wallet.linkFunc}>
                  Link {wallet.displayName} Wallet
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(LinkWallet);
