import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import Button from "../components/Button";

import Draggable from "react-draggable";

import { useCentrifuge } from "../hooks/useCentrifuge";
import { UIContext } from "../stores/uiStore";

const GameLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }

    if (!user.activeCharacter) {
      navigate("/character/select");
    }
  }, [user.isLoggedIn]);

  console.log("@ location?.pathname", location?.pathname);

  return (
    <div className="flex flex-col w-screen h-screen">
      <div
        className={`flex flex-grow flex-col ${
          location?.pathname === "/" ? "" : "mt-20"
        }`}
      >
        <Outlet />
      </div>

      {/* Header */}
      <div
        className="absolute left-0 right-0 top-0 h-20 z-[999] flex flex-row justify-center"
        style={{
          background:
            "linear-gradient(90deg, rgba(16, 59, 57, 0.80) 0%, rgba(18, 32, 40, 0.80) 100%)",
        }}
      >
        {/* Left */}
        <div className="absolute top-0 left-0 mt-1.5 flex flex-row">
          <img className="hidden md:flex" src="/logo-header-ingame.png" />

          <div className="flex flex-col ml-5 md:-ml-16">
            <p className="text-2xl font-['Iceland'] text-white">
              {/* Location:{" "} */}
              <span
                className="cursor-pointer hover:text-green-400"
                onClick={() => {
                  navigate("/");
                }}
              >
                Paragon City
              </span>
            </p>
            <p className="text-2xl font-['Iceland'] text-white">11:22:33</p>
          </div>
        </div>

        {/* Middle */}
        <div className="text-white hidden md:flex">
          <p>Middle</p>
        </div>

        {/* Right */}
        <div className="absolute right-0 top-0 h-20 flex flex-row items-center pr-5">
          {/* Notifications */}
          <div className="mr-3 md:mr-7">
            <NotificationBell count={100} />
          </div>

          {/* Profile */}
          <div
            className="flex flex-row mr-3 md:mr-7 cursor-pointer items-center"
            onClick={() => {
              navigate(`/profile/${user?.activeCharacter.ID}`);
            }}
          >
            <AccountHeart />
            <p className="hidden md:block text-2xl text-white font-['Iceland'] select-none">
              Profile
            </p>
          </div>

          {/* Exit */}
          <p
            onClick={() => {
              navigate("/character/select");
            }}
            className="text-2xl text-white font-['Iceland'] cursor-pointer select-none"
          >
            Exit
          </p>
        </div>
      </div>

      {/* <Draggable defaultPosition={{ x: 0, y: 0 }} bounds="parent">
        <div className="absolute bottom-0 bg-gray-400 left-0 z-[999]">
          <p>Something</p>
        </div>
      </Draggable> */}

      <GameIconMenu />

      {/* <Draggable bounds="parent" axis="y" handle=".handle"> */}
      <SocialTabs />
      {/* </Draggable> */}

      <Chat />
    </div>
  );
};

const Chat = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [channels, setChannels] = useState([]);

  const uiStore = useContext(UIContext);

  const chatBottom = useRef(null);

  const [centrifuge] = useCentrifuge({
    onConnecting: function (ctx) {
      console.log("@ i am connecting", ctx);
    },
    onConnected: function (ctx) {
      console.log("@ I AM CONNECTED", ctx);
    },
    onSubscribed: function (ctx) {
      setChannels((prevChannels) => [...prevChannels, ctx?.channel]);
    },
    onDisconnected: function (ctx) {
      console.log("@ i disconnected", ctx);
    },
    onPublication: (ctx) => {
      handlePublication(ctx);
    },
    onError: (ctx) => {
      console.log("@ got err", ctx);
    },
  });

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!centrifuge) return;
    for (let chan of channels) {
      // Temp hack
      if (chan === "global") {
        tryFetchHistory(chan);
      }
    }
  }, [centrifuge, channels]);

  async function tryFetchHistory(channel) {
    console.log("@ trying to fetch history for channel", channel);

    try {
      let res = await centrifuge?.history(channel, {
        limit: 100,
      });

      console.log("@ res", res);
      const { publications } = res;

      setMessages(() => [...[], ...publications.map((p) => p.data)]);
    } catch (err) {
      console.log("@ history err", err);
    }
  }

  async function handlePublication(ctx) {
    // Called when server sends Publication over server-side subscription.
    console.log("publication receive from server-side channel", ctx);

    setMessages((prevMessages) => [...prevMessages, ctx?.data]);
  }

  function scrollToBottom() {
    chatBottom.current?.scrollIntoView({ behavior: "smooth" });
  }

  async function sendMessage() {
    if (!message) return;

    centrifuge.publish("global", { message }).then(
      function (res) {
        console.log("successfully published");
        setMessage("");
      },
      function (err) {
        console.log("publish error", err);
      }
    );
  }

  // do this???
  if (location?.pathname !== "/") return null;

  if (uiStore.chatCollapsed) {
    return (
      <div
        onClick={() => {
          uiStore.setCollapsed("chat", false);
        }}
        className="absolute bottom-0 left-0 z-[999] cursor-pointer"
      >
        <div></div>

        <div
          className="flex items-center px-7 py-2 rounded-tr-md rounded-br-md"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.80) 14.35%, rgba(0, 0, 0, 0.20) 251.79%)",
          }}
        >
          <p className="text-[#9D9D9D] font-['Iceland'] text-2xl mr-2">
            Expand chat
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
          >
            <path
              d="M5.54921 8.12268C5.54921 8.59115 5.92899 8.97093 6.39746 8.97093C6.86593 8.97093 7.24571 8.59115 7.24571 8.12268H5.54921ZM6.99726 0.736909C6.666 0.405648 6.12892 0.405648 5.79766 0.736909L0.399454 6.13512C0.068193 6.46638 0.068193 7.00346 0.399454 7.33472C0.730714 7.66598 1.26779 7.66598 1.59905 7.33472L6.39746 2.53631L11.1959 7.33472C11.5271 7.66598 12.0642 7.66598 12.3955 7.33472C12.7267 7.00346 12.7267 6.46638 12.3955 6.13512L6.99726 0.736909ZM7.24571 8.12268L7.24571 1.33671H5.54921L5.54921 8.12268H7.24571Z"
              fill="#9D9D9D"
            />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div
      className="absolute bottom-0 left-0 z-[999] p-2"
      style={{
        background: "linear-gradient(180deg, #24374C 20.13%, #0D1C27 89.15%)",
        filter: "drop-shadow(5px 1px 4px rgba(0, 0, 0, 0.25))",
      }}
    >
      <div className="h-40 overflow-y-scroll mb-2 w-[350px] px-2 mt-7">
        {messages.map((data, i) => {
          return (
            <div key={`message-${i}`} className="flex flex-row my-2">
              <img
                className="rounded-full bg-white mr-2 cursor-pointer h-7 w-7 min-w-[1.75rem] max-h-7"
                onClick={() => {
                  console.log("@ view profile???", data);

                  navigate(`/profile/${data?.userId}`);
                }}
              />
              <p className="text-white font-['Inter'] text-md">
                <span
                  className="mr-2 text-[#7BD7BC] font-['Iceland'] text-xl cursor-pointer"
                  onClick={() => {
                    console.log("@ view profile???");
                    navigate(`/profile/${data?.userId}`);
                  }}
                >
                  {data?.username}:
                </span>
                {data?.message}
              </p>
            </div>
          );
        })}
        <div ref={chatBottom} />
      </div>

      <div className="relative flex flex-col">
        <input
          type="text"
          className="px-2 py-2 font-['Inter'] text-white"
          style={{
            border: "1px solid #404040",
            background: "rgba(0, 0, 0, 0.40)",
          }}
          placeholder="Enter message... (Press Enter)"
          value={message}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />

        {/* <div
          className=""
          style={{
            width: 35,
            height: 35,
            background:
              "url(/chat/send-icon.png) lightgray 50% / cover no-repeat",
          }}
          onClick={() => {
            sendMessage();
          }}
        ></div> */}
      </div>

      <div
        className="absolute top-0 right-0 p-2 cursor-pointer"
        onClick={() => {
          uiStore.setCollapsed("chat", true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M16.6008 4.86372C16.932 4.53246 16.932 3.99538 16.6008 3.66412C16.2695 3.33285 15.7324 3.33285 15.4012 3.66412L16.6008 4.86372ZM10.3543 9.06232C10.3543 9.5308 10.7341 9.91057 11.2026 9.91057L18.8368 9.91057C19.3053 9.91057 19.685 9.5308 19.685 9.06232C19.685 8.59385 19.3053 8.21408 18.8368 8.21408L12.0508 8.21408L12.0508 1.4281C12.0508 0.959631 11.671 0.579859 11.2026 0.579859C10.7341 0.579859 10.3543 0.959632 10.3543 1.4281L10.3543 9.06232ZM15.4012 3.66412L10.6028 8.46252L11.8024 9.66212L16.6008 4.86372L15.4012 3.66412Z"
            fill="white"
          />
          <path
            d="M3.71563 15.7932C3.38437 16.1245 3.38437 16.6615 3.71563 16.9928C4.04689 17.3241 4.58397 17.3241 4.91523 16.9928L3.71563 15.7932ZM9.96208 11.5946C9.96208 11.1261 9.58231 10.7464 9.11383 10.7464L1.47962 10.7464C1.01114 10.7464 0.631371 11.1261 0.631372 11.5946C0.631372 12.0631 1.01114 12.4428 1.47962 12.4428L8.26559 12.4428L8.26559 19.2288C8.26559 19.6973 8.64536 20.0771 9.11384 20.0771C9.58231 20.0771 9.96208 19.6973 9.96208 19.2288L9.96208 11.5946ZM4.91523 16.9928L9.71364 12.1944L8.51403 10.9948L3.71563 15.7932L4.91523 16.9928Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
});

const SocialTabs = observer(() => {
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();

  const uiStore = useContext(UIContext);

  // do this???
  if (location?.pathname !== "/") return null;

  let activeBg = activeTab === "guild" ? "rgba(54,82,47,1)" : "gray";
  let friendTabColor = activeTab === "friends" ? "gray" : "#222";
  // let guildTabColor = activeTab === "guild" ? "rgba(54,82,47,1)" : "#222";

  if (!uiStore.socialTabsCollapsed) {
    return (
      <div
        className="absolute left-0 top-1/3 z-[999] flex flex-col"
        style={{
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        }}
      >
        <div className="flex flex-row bg-transparent">
          {/* Friends Tab */}
          <div
            className="py-2 pl-4 pr-8 mr-2 cursor-pointer"
            style={{
              backgroundColor: friendTabColor,
              clipPath: "polygon(0 0, 85% 0, 100% 60%, 100% 100%, 0 100%)",
            }}
            onClick={() => {
              setActiveTab("friends");
            }}
          >
            <div className="flex flex-row items-center">
              <p className="text-white font-['Iceland'] text-2xl mr-2">
                Friends
              </p>
              <div className="flex flex-row items-center">
                <div className="w-1 h-1 bg-[#31F910] rounded-full mr-1" />
                <p className="text-white font-['Iceland'] text-2xl">13</p>
              </div>
            </div>
          </div>

          {/* Guild Tab */}
          <div
            className="py-2 pl-4 pr-8 cursor-pointer"
            style={{
              backgroundColor: "rgba(54,82,47,1)",
              clipPath: "polygon(0 0, 85% 0, 100% 60%, 100% 100%, 0 100%)",
            }}
            onClick={() => {
              setActiveTab("guild");
            }}
          >
            <div className="flex flex-row items-center">
              <p className="text-white font-['Iceland'] text-2xl mr-2">Guild</p>

              <div className="flex flex-row items-center">
                <div className="w-1 h-1 bg-[#31F910] rounded-full mr-1" />
                <p className="text-white font-['Iceland'] text-2xl">13</p>
              </div>
            </div>
          </div>
        </div>

        {/* Content */}
        <div
          className="relative flex flex-col px-5"
          style={{ background: activeBg }}
        >
          <div className="py-5">
            <p>Some text</p>
            <p>Some text</p>
            <p>Some text</p>
            <p>Some text</p>
            <p>Some text</p>
            <p>Some text</p>
          </div>

          <div
            onClick={() => {
              uiStore.setCollapsed("socialTabs", true);
            }}
            className="absolute top-0 right-0 p-4 cursor-pointer"
          >
            {/* <p className="text-white">{"<<"}</p> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6 9L2 5L6 1"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="absolute left-0 top-1/3 z-[999]">
      <div
        onClick={() => {
          setActiveTab("friends");
          uiStore.setCollapsed("socialTabs", false);
        }}
        className="handle flex flex-col items-center cursor-pointer bg-[#222] px-2 py-7 mb-1 border-b border-t border-white"
        style={{
          clipPath: `polygon(100% 0, 100% 80%, 55% 100%, 0 100%, 0 0)`,
        }}
      >
        <div className="flex flex-row items-center">
          <div className="w-1 h-1 bg-[#31F910] rounded-full mr-1" />
          <p className="text-white font-['Iceland'] text-2xl">13</p>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="22"
          viewBox="0 0 27 22"
          fill="none"
        >
          <path
            d="M18.0942 0C16.2773 0 14.7099 0.947909 13.748 2.38331C15.3511 3.8458 16.313 5.84995 16.313 8.15201C16.313 8.93742 16.2061 9.64158 15.9923 10.3457C16.6692 10.6437 17.3461 10.8603 18.0942 10.8603C21.051 10.8603 23.4378 8.42284 23.4378 5.4437C23.4378 2.46456 21.051 0.0270831 18.0942 0.0270831V0ZM9.85337 2.30056C6.89655 2.30056 4.50971 4.73804 4.50971 7.71718C4.50971 10.6963 6.89655 13.1338 9.85337 13.1338C12.8102 13.1338 15.197 10.6963 15.197 7.71718C15.197 4.73804 12.8102 2.30056 9.85337 2.30056ZM24.3285 11.2666C22.7966 12.6478 20.6948 13.4874 18.3079 13.5416C19.2698 14.5707 19.8754 15.8165 19.8754 17.1707V18.9582H27.0003V14.4624C27.0003 13.0541 25.8959 11.8353 24.3285 11.2395V11.2666ZM3.6191 13.5671C2.05162 14.163 0.947266 15.3817 0.947266 16.79V21.2858H18.7595V16.79C18.7595 15.3817 17.6551 14.163 16.0877 13.5671C14.4846 15.0025 12.3115 15.8421 9.85337 15.8421C7.39529 15.8421 5.2222 14.9754 3.6191 13.5671Z"
            fill="#FFFEFD"
          />
        </svg>
      </div>

      <div
        onClick={() => {
          setActiveTab("guild");
          uiStore.setCollapsed("socialTabs", false);
        }}
        className="handle flex flex-col items-center cursor-pointer bg-[rgba(54,82,47,1)] px-2 py-7 border-b border-t border-white"
        style={{
          clipPath: `polygon(100% 0, 100% 80%, 55% 100%, 0 100%, 0 0)`,
        }}
      >
        <div className="flex flex-row items-center">
          <div className="w-1 h-1 bg-[#31F910] rounded-full mr-1" />
          <p className="text-white font-['Iceland'] text-2xl">13</p>
        </div>

        <div>
          <img width={30} src="/game-layout/gameplay-icons/guild.png" />
        </div>
      </div>
    </div>
  );
});

const GameIconMenu = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const uiStore = useContext(UIContext);

  // do this???
  if (location?.pathname !== "/") return null;

  return (
    <>
      {uiStore.iconsCollapsed && (
        <div
          onClick={() => {
            uiStore.setCollapsed("icons", false);
          }}
          className="absolute right-0 z-[999] top-1/4 bg-black p-4 cursor-pointer"
        >
          {/* <p className="text-white">{"<<"}</p> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
          >
            <path
              d="M6 9L2 5L6 1"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      )}

      <div className="absolute w-[220px] right-0 z-[999] top-1/4 flex flex-col items-center self-stretch">
        {!uiStore.iconsCollapsed && (
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="auto"
              height="51"
              viewBox="0 0 265 51"
              fill="none"
              style={{
                fill: "rgba(0, 0, 0, 0.50)",
                strokeWidth: 1,
                stroke: "#FFF",
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
            >
              <g filter="url(#filter0_bd_1781_2492)">
                <path
                  d="M274 10C274 4.47715 269.523 5.07678e-08 264 1.13393e-07L25.4773 2.81807e-06C22.0649 2.85677e-06 18.8881 1.74003 17.0507 4.61547L5.57346 22.5769C4.54596 24.1849 4 26.0532 4 27.9614V33C4 38.5229 8.47714 43 14 43H264C269.523 43 274 38.5228 274 33V10Z"
                  fill="black"
                  fill-opacity="0.5"
                  shape-rendering="crispEdges"
                />
                <path
                  d="M273.5 10C273.5 4.75329 269.247 0.5 264 0.5L25.4773 0.500003C22.2355 0.500003 19.2176 2.15303 17.472 4.8847L5.99478 22.8461C5.01868 24.3737 4.5 26.1486 4.5 27.9614V33C4.5 38.2467 8.7533 42.5 14 42.5H264C269.247 42.5 273.5 38.2467 273.5 33V10Z"
                  stroke="white"
                  shape-rendering="crispEdges"
                />
              </g>
              <defs>
                <filter
                  id="filter0_bd_1781_2492"
                  x="-11"
                  y="-15"
                  width="300"
                  height="73"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_1781_2492"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_1781_2492"
                    result="effect2_dropShadow_1781_2492"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1781_2492"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>

            <div className="absolute top-0 left-0 right-0 pt-2 flex justify-center items-center">
              <p className="text-white font-['Iceland'] text-xl">
                Gameplay Icons
              </p>

              <div
                className="absolute right-0 bg-black cursor-pointer px-2"
                onClick={() => {
                  uiStore.setCollapsed("icons", true);
                }}
              >
                {/* <p className="text-white">{">>"}</p> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="10"
                  viewBox="0 0 8 10"
                  fill="none"
                >
                  <path
                    d="M1.8667 1L5.8667 5L1.8667 9"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}

        <div
          className="py-5 px-5 -mt-2 text-white font-['Iceland'] flex justify-center self-stretch"
          style={{
            background:
              "linear-gradient(180deg, #24374C 20.13%, #0D1C27 89.15%)",
            boxShadow: "5px 1px 4px 0px rgba(0, 0, 0, 0.25)",
            display: uiStore.iconsCollapsed ? "none" : "grid",
            maxWidth: 265,
          }}
        >
          <div className="grid gap-6 grid-cols-[repeat(2,50px)] justify-items-center">
            <img
              onClick={() => {
                // TODO: Open inventory modal??
              }}
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/inventory.png"
            />
            <img
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/bank.png"
            />
            <img
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/mail.png"
            />
            <img
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/skills.png"
            />
            <img
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/events.png"
            />
            <img
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/idunno.png"
            />
            <img
              onClick={() => {
                // TODO: Go to guild page etc
              }}
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/guild.png"
            />
            <img
              className="select-none cursor-pointer"
              src="/game-layout/gameplay-icons/quests.png"
            />
          </div>
        </div>
      </div>
    </>
  );
});

const AccountHeart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="37"
      viewBox="0 0 46 37"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03848 26.4983L31.3983 2.13848L29.5598 0.300003H45.76V36.7H0V0.300003H25.8829L3.36152 22.8213L7.03848 26.4983ZM37.8983 9.93847L13.5385 34.2983L9.86152 30.6213L34.2213 6.26152L37.8983 9.93847Z"
        fill="url(#pattern0_1688_489)"
      />
      <defs>
        <pattern
          id="pattern0_1688_489"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1688_489"
            transform="matrix(0.00795455 0 0 0.01 0.102273 0)"
          />
        </pattern>
        <image
          id="image0_1688_489"
          width="100"
          height="100"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEM0lEQVR4nO3dy4vVZRzH8UdtDDNTSu0yCrkyFy1CG3MM/wMjKMQRgkw3ithCcKqpEBK1hZfNpKOoDdOisIULxUGbxaQugsJ7FhkhJSoSYV7S0Zm3PMy3bHFGz+/M77md5/sCV8LM9/v7zO/8znluxxillFJKKaWUUkoppZSKGfAoMBdYCnwKdABfAp1AO7AamA9M9ljTZOA1oFVq6JSaOqRGW2uzrd3UA2A8sAQ4BNykOgPAMeADYKqDmqYCbcBx+V3VuAEcBN4BnjCpAaYAW6SR4egDtpURjATRIT9zOK4Dm4FGk8jL0kcF7oZq3QI2AY/VUNNYuYD2Z5Tphtxpo02MgBnAKdw6C8wsUNMs4CfHNZ0AXjAxAV6XW9mHPmB5FTWtKOHlqVrX7JsDEwNgMXAX/9YBIyrUM0LeIfl2B3g7TAr3m18A9BNOe4Uwtgasx/5hvhkqjDnAbcJb+7+a7F0Tmn3z0OQ7jCeB88SjBVhEPH4DJvgMZBdxue7xTUW1dvgKo7nAJ9yc9QOzfQTSE7rThHS7DqMpdIcJmukyEDsWpIr5zFUYo4G/Chaj4E+gwUUg8/Tq1qzZRSAf115P9tpcBPJ19pe1dl+5CMT10Ho9O+EikCuhu0rYZReBlD3jlpObGkhcbrkI5GLorhJ2wUUgZ0J3lbBTLgI5HLqrhPW6CKQrdFcJ63QRyLuhu0rYCheB2HW5qjavuAhkbKDlPqmza8PGlB6IhNIbursE9TgJQwJZGbq7BC13GUijLnAovNDhOWeBSChHi9WUtV6nYUggb4XuMiEtPgJpAH4P3WkC/nAylz5EKHajinqwVi9hSCATZU+Equxvu/7ZWyASypohilHwodcwJJDHdY6kogt2VMN7IBLKsso1ZW1JkDAkkEdkv7ca9AMwKlggEsrLOuj43x5DdwuriwA2DtaUtfUmFnZDP/Ar+frZ2RD7MF+6fO0Lj0mfkwmoMgDvk59VJlbASDk1JxcHKh1eEBXg6UwGH88Dk0wKgJdKOJopZva0o1kmJfaIiTqdXRwAFpoURXLURdk+MamSw2B2Uz++iP4hXuUMYzfp+yba0+OKAsYB35Ou74INqTueZUxxn+JJ4ClTj+Sc3B9Ja4zqGVPP5AjZFAYif3G+yC0WwLORv3ydtX84JidyKp19WMY46zfJ5EiOIj9CPL61NZmcyeRWDJ9TeuxKmtDXIwpy9NOegGHsrZtvQCh5BcvOAGFsD75SJGYMbi7t9zRquyZ0v0kA3nA8n/KPl60C9QSYDVxyEIY91ejV0P0lCZhW8pEep4HnQ/eVNGACsL+EMPZl/xmj5Imu1hof9vbhvcGuiCmtIDVIvsXtaoEwrgX7+ohcANNl8O9hzgEvhq43p4HJriFWtQzI//ndUqbMv3fLe8Dn8s8+Z6brtVFKKaWUUkoppZRSSpnc3APk2rVYLaw9AAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

const NotificationBell = ({ count = 0, onClick = () => {} }) => {
  const showIndicator = count > 0;

  let rightVal = "0.35";
  if (count > 9) {
    rightVal = "0.20";
  }

  let addPlus = false;
  if (count > 99) {
    count = 99;
    addPlus = true;
    rightVal = "-0.25";
  }

  return (
    <div className="relative cursor-pointer" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
      >
        <path
          d="M13.6116 6.22656C9.8684 6.22656 6.80579 9.28938 6.80579 13.0328C6.80579 16.5721 5.03629 19.771 2.24591 22.0851C0.850724 23.2422 0 24.8757 0 26.6453H27.2232C27.2232 24.8757 26.4065 23.2422 24.9773 22.0851C22.1869 19.771 20.4174 16.5721 20.4174 13.0328C20.4174 9.28938 17.3888 6.22656 13.6116 6.22656ZM10.2087 30.0484C10.2087 31.9202 11.74 33.4516 13.6116 33.4516C15.4832 33.4516 17.0145 31.9202 17.0145 30.0484H10.2087Z"
          fill="white"
        />
        {showIndicator && (
          <path
            d="M23.3166 0.948425L31.6331 5.74999V15.3531L23.3166 20.1547L15 15.3531V5.74999L23.3166 0.948425Z"
            fill="#FF2727"
          />
        )}
        {/* <path
        d="M26.3475 14.9776H20.0455V13.0869L24.907 9.52178V7.41509H21.4859V8.6755H20.0455V7.41509C20.0455 7.06698 20.1655 6.77288 20.4056 6.5328C20.6577 6.28072 20.9578 6.15468 21.3059 6.15468H25.0871C25.4352 6.15468 25.7293 6.28072 25.9694 6.5328C26.2215 6.77288 26.3475 7.06698 26.3475 7.41509V10.0259L21.576 13.5371V13.7171H26.3475V14.9776Z"
        fill="white"
      /> */}
      </svg>

      {showIndicator && (
        <div
          className={`absolute top-[0.15rem]`}
          style={{
            right: `${rightVal}rem`,
          }}
        >
          <p className="text-white text-xs">
            {count}
            {addPlus ? "+" : ""}
          </p>
        </div>
      )}
    </div>
  );
};

export default observer(GameLayout);
