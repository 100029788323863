import { Link, Outlet, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useContext, useEffect } from "react";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";

function App() {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }
  }, [user.isLoggedIn]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p>Header</p>
        <Link to={"/login"}>Login</Link>
        <Button
          onClick={() => {
            user.logout();
          }}
        >
          Logout
        </Button>
      </div>

      <Outlet />
    </div>
  );
}

export default observer(App);
