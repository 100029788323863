import axios from "axios";
import user from "../stores/userStore";
import { GAME_API } from "./config";

export const api = axios.create({
  baseURL: GAME_API,
  timeout: 30000, // 30 seconds
  headers: {},
});

export async function signup({ email, password }) {
  let res = await api.post("/user/signup", {
    email,
    password,
  });

  console.log("@ signup res", res);
}

export async function verifyEmail({ token }) {
  return await api.post("/user/verify-email", {
    token,
  });
}

export async function login({ email, password }) {
  return await api.post("/user/login", {
    email,
    password,
  });
}

export async function linkWaxWallet(body) {
  return await api.post("/user/link-wallet/wax", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getLinkedWallets() {
  return await api.get("/user/linked-wallets", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function waxLogin(body) {
  return await api.post("/oauth/wax", body);
}

export async function checkUsername(username) {
  return await api.get("/characters/check-username", {
    params: {
      username,
    },
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function createCharacter(body) {
  return await api.post("/characters", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacters() {
  return await api.get("/characters", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacterSlots() {
  return await api.get("/characters/slots", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function enterWorld(body) {
  return await api.post("/characters/enter-world", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacterDeposits() {
  return await api.get("/characters/deposits", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacterWithdrawals() {
  return await api.get("/characters/withdrawals", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getDeposits() {
  return await api.get("/waygate/deposits", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getWithdrawals() {
  return await api.get("/waygate/withdrawals", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function claimDepositItems(body) {
  return await api.post("/waygate/claim-items", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getInventory() {
  return await api.get("/inventory", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function withdrawItems(items, blockchain = "wax") {
  return await api.post(
    "/waygate/withdraw-items",
    {
      Blockchain: blockchain,
      Items: items,
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function withdrawCharacter(characterId, blockchain = "wax") {
  return await api.post(
    "/characters/withdraw",
    {
      Blockchain: blockchain,
      CharacterID: characterId,
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function addCharacterToSlot(characterId, slotId) {
  return await api.post(
    "/characters/slots/add",
    {
      CharacterID: Number(characterId),
      SlotID: Number(slotId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function removeCharacterFromSlot(characterId, slotId) {
  return await api.post(
    "/characters/slots/remove",
    {
      CharacterID: Number(characterId),
      SlotID: Number(slotId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}
