import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { Field, Form, Formik } from "formik";
import { api, createCharacter } from "../lib/api";
import { observer } from "mobx-react-lite";

const Account = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
    }
  }, [user.isLoggedIn]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1>Account</h1>
      </div>

      <div>
        <p>Setup 2FA</p>
        <Button>Setup</Button>
      </div>
    </div>
  );
};

export default observer(Account);
