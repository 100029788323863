import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import Button from "../components/Button";
import { Field, Form, Formik } from "formik";
import { createCharacter } from "../lib/api";
import { observer } from "mobx-react-lite";

const CharacterCreate = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
      return;
    }
  }, [user.isLoggedIn]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h1 className="font-bold text-2xl">Create New Character</h1>
      </div>

      <div>
        <Formik
          initialValues={{ username: "" }}
          validate={(values) => {
            const errors = {};
            console.log("@ validating", values);

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log("@ Got Submit", values);

            let characterSlotId = searchParams.get("slotId");

            try {
              let res = await createCharacter({
                Username: values.username,
                CharacterSlotID: Number(characterSlotId),
              });

              console.log("@ res", res);

              // loadCharacterSlots();
              navigate("/character/select");
            } catch (err) {
              console.log("@ err", err);
            }

            setSubmitting(false);
            resetForm();
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form>
              <Field
                id="username"
                name="username"
                type="text"
                required
                className="my-2 block w-full rounded-md border-0 pl-3 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <Button
                loading={isSubmitting}
                disabled={isSubmitting}
                type="submit"
              >
                Create Character
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default observer(CharacterCreate);
